import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = ({ location }) => (
  <Layout location={location} title="About Us">
    <SEO title="About Us" />
    <section id="earthseeds-page" className="page">
      <div className="container text-white">
        <div className="content bg-black p-5">
          <p>
            Global View Foundation is a Colorado non-profit focused on affecting
            public consciousness about this planet we all call home. By
            regarding Earth as a living system and all of its inhabitants as
            ‘crewmembers’ we share in the responsibility in preserving and
            appreciating the essential common ground of all living things.
          </p>
          <p>
            We playfully see this beautiful, organic planet as The Astronaut’s
            Globe and as the Crew we should function dynamically, effectively
            and synergistically towards our greatest possible future.
            Buckminster Fuller and the Spaceship Earth concept is a central
            inspiration to our work.
          </p>
          <p>
            Global View produces Earth image products and educational programs
            for use in schools, places of worship, camps, fundraisers, events
            and organizations in an effort to promote a sustainable worldview.
          </p>
          <p>
            We’ve been hibernating for some time but now really excited to be
            back into the spin of things! We were pulled away from this endeavor
            unfortunately and the organization adapted. We’ve been able to
            maintain a minimal presence online and in our communities, and kept
            our non-profit status alive in good standing. The passion has never
            faded, and we are reminded now of our once proclaimed ‘2020 Vision.’
          </p>
          <p>
            <img src="/assets/img/CNA-social-media.png" alt="cna" />
          </p>
        </div>
      </div>
    </section>
  </Layout>
)

export default AboutPage
